<template>
  <div>
    <div class="scheme-item">
      <div class="title">忌口</div>
      <div class="content">{{ schemeData.schemes.avoid_food }}</div>
    </div>
    <div class="scheme-item">
      <div class="title">饮食调整方案</div>
      <div class="content">{{ schemeData.schemes.diet_plan }}</div>
    </div>
    <div class="scheme-item">
      <div class="title">运动方案</div>
      <div class="content">{{ schemeData.schemes.sport_plan }}</div>
    </div>
    <div class="scheme-item">
      <div class="title">调理备注</div>
      <div class="content">{{ schemeData.schemes.notes }}</div>
    </div>
    <div class="scheme-item">
      <div class="title">现有方案</div>
      <div class="content">
        <el-table :data="schemeData.cases" border>
          <el-table-column prop="name" label="营养素名称" />
          <el-table-column prop="use_num" label="用量">
            <template #default="{ row }">
              <span>{{ row.use_num }} {{ row.usages_unit }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="usages" label="用法">
            <template #default="{ row }">
              <span>{{ row.usages }} 次/天</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="scheme-item">
      <div class="title">原有方案</div>
      <div class="content">
        <el-table :data="schemeData.original" border>
          <el-table-column prop="name" label="营养素名称" />
          <el-table-column prop="use_num" label="用量">
            <template #default="{ row }">
              <span>{{ row.use_num }} {{ row.usages_unit }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="usages" label="用法">
            <template #default="{ row }">
              <span>{{ row.usages }} 次/天</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, onMounted } from 'vue'
import axios from '@/libs/axios'
const schemeData = reactive({
  cases: [],
  schemes: {},
  original: []
})

const getScheme = async () => {
  const response = await axios.post('/schemes/get', {})
  Object.assign(schemeData, response)
}

onMounted(async () => {
  await getScheme()
})
</script>
<style lang="less" scoped>
.scheme-item {
  .content {
    padding: 0 20px;
  }
}
.title {
  margin: 20px 0;
  padding: 0 20px;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: #3aa20f;
    display: inline-block;
  }
}
</style>
